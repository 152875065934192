import { Injectable } from '@angular/core';
import { DefaultPopup } from '../../helpers/base-ae/default-popup.class';
import { DefaultOverlayComponent } from '../../ui/default-overlay/default-overlay.component';

@Injectable({
    providedIn: 'root'
})
export class OverlayService extends DefaultPopup<DefaultOverlayComponent> {
    
    constructor() {
        super('robocode-overlay-ae', undefined, DefaultOverlayComponent);
    }

    override setupVariables(...arg: any[]): void {
        if (!this.popupEl) {
            return;
        }
        this.popupEl.isSpinnerShow = true;
    }
}
